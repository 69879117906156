import "./src/styles/main.scss";

export const onClientEntry = () =>{
  window.onload = () => { 
    if(localStorage.getItem("data-theme") == null){
      localStorage.setItem("data-theme", "light");
    } 
    document.documentElement.setAttribute('data-theme', localStorage.getItem("data-theme"));
  }

}

export const onInitialClientRender = () => {    
  document.getElementById('theme-icon').setAttribute('data-theme', localStorage.getItem("data-theme"));
}

export const onRouteUpdate = () => {
  document.getElementById('theme-icon').setAttribute('data-theme', localStorage.getItem("data-theme"));
}